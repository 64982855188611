import { memo, useEffect, useState, MouseEvent } from 'react';
import { Box, Avatar, Menu, MenuItem, IconButton, Tooltip, Typography, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';

import { UserProfile } from '@/types/Organization';
import { useLocale } from '@/providers/LocaleProvider';
import {
  ENTITY_STATUS,
  FIRST_ITEM_INDEX,
  FIRST_ITEM_NUMBER,
  mappingLanguageOptionsFromLanguageCode,
} from '@/constants';
import RoutesString from '@/pages/routesString';
import { checkArrayHasLength, navigateDefault, translateRoleProfile } from '@/utils/commonUtil';
import useProfilesQuery from '@/utils/hooks/dashboard/useProfilesQuery';
import useTransferTokenQuery from '@/utils/hooks/dashboard/useTransferTokenQuery';
import useAuthManagement from '@/store/authManagement/AuthManagement';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';

import userIconPath from '@/assets/images/icons/user-icon.svg';
import orgIconPath from '@/assets/images/icons/org-icon.svg';
import logoutIcon from '@/assets/images/icons/logout-original-icon.svg';
// import echAvaImg from '@/assets/images/ech-ava.png';
import { CustomSvg } from '../menuItemBtn/styles';
import { styles } from './styles';
import { ELocaleCode } from '@/constants/enums';
import { UserMenuItemProps } from './types';
import { UserRoleEnum } from '@/types/Dashboard';

const TitleSection = ({ title }: { title: string }) => {
  return (
    <Box mx="16px">
      <Typography sx={styles.menuSection}>
        <FormattedMessage id={title} />
      </Typography>
    </Box>
  );
};

const UserMenuItem = ({ icon, onClick, img, messageId, customContent, sx, marginLeft }: UserMenuItemProps) => {
  return (
    <MenuItem onClick={onClick}>
      <Box sx={{ ...styles.blockUser, ...sx }}>
        {icon ? <CustomSvg className="svg" src={icon} /> : <Box component="img" sx={styles.avaImg} src={img} />}
        {marginLeft && <Box ml="10px" />}
        {customContent || (
          <Typography sx={styles.menuItemText}>
            <FormattedMessage id={messageId} />
          </Typography>
        )}
      </Box>
    </MenuItem>
  );
};

export const UserMenu = memo(() => {
  const [authMgtState, authMgtActions] = useAuthManagement();
  const { token: authToken } = authMgtState;
  const [profileMgtState, profileMgtActions] = useProfileManagement();
  const { activeOrganization: activeOrganizeItem, activeUser } = profileMgtState;

  const [hoverMenu, setHoverMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryClient = useQueryClient();
  const [currentUser, setCurrentUser] = useState<UserProfile>();
  const [orgIdCollapsed, setOrgIdCollapsed] = useState<number | null>(null);
  const [targetOrgId, setTargetOrgId] = useState(activeOrganizeItem?.organizationId);

  const { data: dataProfiles, refetch } = useProfilesQuery(authToken);
  const { data: dataTransfer } = useTransferTokenQuery(
    {
      userId: currentUser?.userId,
      organizationId: targetOrgId,
    },
    authToken,
  );

  const {
    userFirstName: activeUserFirstName,
    userId: activeUserId,
    schoolName: activeSchoolName,
    roleId: activeRoleId,
  } = activeUser ?? {};

  const navigate = useNavigate();
  const location = useLocation();
  const { locale, setLocale } = useLocale();

  const handleSwitchLanguage = (lang: ELocaleCode) => {
    setLocale(lang);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserId, activeOrganizeItem?.organizationId]);

  useEffect(() => {
    if (dataTransfer) {
      authMgtActions.setTransferToken(dataTransfer.token);
      profileMgtActions.setProfileInfo({
        activeUser: currentUser,
      });

      currentUser && navigateDefault(currentUser, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTransfer, currentUser]);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (activeRoleId !== Number(UserRoleEnum.ORG_ADMIN))
      return setOrgIdCollapsed(activeOrganizeItem?.organizationId ?? null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOrgIdCollapsed(null);
  };

  const handleSwitchUser = async ({ user, orgId }: { user: UserProfile; orgId: number }) => {
    handleClose();
    const isCurrentActiveOrgId = orgId === activeOrganizeItem?.organizationId;
    if (user.userStatus === ENTITY_STATUS.INACTIVE || (isCurrentActiveOrgId && user.userId === activeUserId)) {
      return;
    }

    if (!isCurrentActiveOrgId) {
      const newOrg = dataProfiles?.organizations?.find((org) => org.organizationId === orgId);
      profileMgtActions.setProfileInfo({ activeOrganization: newOrg });
    }
    setTargetOrgId(orgId);
    queryClient.clear();
    setCurrentUser(user);
  };

  const handleOpenOrg = (organizationId: number | null) => {
    setOrgIdCollapsed(organizationId === orgIdCollapsed ? null : organizationId);
  };

  const handleLogout = () => {
    authMgtActions.reset();
    profileMgtActions.reset();

    navigate(RoutesString.Home);
  };

  // const handleMoveToOrganization = () => {
  //   authMgtActions.setTransferToken('');
  //   profileMgtActions.setProfileInfo({
  //     activeUser: null,
  //   });

  //   navigate(RoutesString.Profile);
  // };

  const calculateShortName = (userName = '') => {
    const arrUserName = userName.split(' ');
    let name = arrUserName[FIRST_ITEM_INDEX].charAt(FIRST_ITEM_INDEX);

    if (arrUserName.length > FIRST_ITEM_NUMBER) {
      name = `${arrUserName[FIRST_ITEM_INDEX].charAt(FIRST_ITEM_INDEX)}${arrUserName[
        arrUserName.length - FIRST_ITEM_NUMBER
      ].charAt(FIRST_ITEM_INDEX)}`;
    }
    return name;
  };

  const renderSwitchLanguage = (lang: ELocaleCode) => {
    const nextLanguage = lang === ELocaleCode.EN ? ELocaleCode.VI : ELocaleCode.EN;

    return (
      <UserMenuItem
        img={mappingLanguageOptionsFromLanguageCode[nextLanguage].flag}
        messageId="menu.dropdown.switchLanguage"
        onClick={() => handleSwitchLanguage(nextLanguage)}
        sx={styles.blockSwitchLanguage}
      />
    );
  };

  return (
    <Box sx={styles.container} key={`user-menu-${location.pathname}`}>
      <Tooltip
        title={activeSchoolName}
        arrow={true}
        componentsProps={{
          tooltip: { sx: styles.tooltip },
        }}
      >
        <Box
          onMouseOver={() => setHoverMenu(true)}
          onMouseOut={() => setHoverMenu(false)}
          onClick={handleClick}
          sx={styles.blockMenu}
        >
          <IconButton size="small">
            <Avatar sx={[styles.avatar, styles.avatarCircle]}>{calculateShortName(activeUserFirstName)}</Avatar>
          </IconButton>

          <Collapse orientation="horizontal" in={hoverMenu || Boolean(anchorEl)}>
            <Box sx={styles.blockName}>
              <Typography className="info name">
                {`${activeUserFirstName} - `}
                <FormattedMessage id={translateRoleProfile(activeRoleId)} />
              </Typography>
              <Typography className="info">{activeSchoolName}</Typography>
            </Box>
            <KeyboardArrowDownIcon />
          </Collapse>
        </Box>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: styles.blockDropdown,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableAutoFocusItem={true}
      >
        {checkArrayHasLength(dataProfiles?.organizations) && (
          <Box sx={styles.blockProfile}>
            <TitleSection title="menu.dropdown.change.organization" />
            {dataProfiles?.organizations?.map((org) => {
              const { organizationId, organizationName, schools, users: orgUsers } = org;

              const allUserInOrg =
                orgUsers?.map((user) => ({ ...user, schoolName: user.schoolName, organizationName })) || [];

              const allUserInSchool =
                schools?.flatMap((school) =>
                  school.users.map((user) => ({
                    ...user,
                    schoolName: school.schoolName,
                    organizationName,
                  })),
                ) || [];

              const userSchoolPairs = [...allUserInOrg, ...allUserInSchool];

              const isCurrentActiveOrg = organizationId === activeOrganizeItem?.organizationId;
              return (
                <Box>
                  <MenuItem
                    key={organizationId}
                    onClick={() => handleOpenOrg(organizationId)}
                    className={classNames({
                      activeUser: isCurrentActiveOrg,
                    })}
                  >
                    <Box sx={styles.blockIcon}>
                      <Box sx={styles.blockUser}>
                        <CustomSvg
                          className={classNames('svg', {
                            activeSvg: isCurrentActiveOrg,
                          })}
                          src={orgIconPath}
                        />
                        <Box ml="10px" />
                        <Typography sx={styles.organizationName}>{organizationName}</Typography>
                      </Box>
                      <KeyboardArrowDownIcon sx={organizationId === orgIdCollapsed ? styles.arrowUp : {}} />
                    </Box>
                  </MenuItem>
                  <Box ml="16px">
                    <Collapse in={orgIdCollapsed === organizationId}>
                      {checkArrayHasLength(orgUsers) &&
                        userSchoolPairs?.map((userInfo, index) => {
                          const { schoolName, userFirstName, userId, roleName, userStatus } = userInfo;
                          if (userStatus === ENTITY_STATUS.INACTIVE) return <></>;
                          return (
                            <Tooltip title={`${userFirstName} - ${roleName}${schoolName ? ` - ${schoolName}` : ''}`}>
                              <MenuItem
                                key={`${userFirstName}-${index}`}
                                onClick={() => handleSwitchUser({ user: userInfo, orgId: organizationId })}
                                className={classNames({
                                  activeUser: userId === activeUserId,
                                })}
                              >
                                <Box sx={styles.blockUser}>
                                  <CustomSvg
                                    className={classNames('svg', {
                                      activeSvg: userId === activeUserId,
                                    })}
                                    src={userIconPath}
                                  />
                                  <Box ml="10px" />
                                  <Box sx={styles.userInfo}>
                                    <Typography noWrap={true} sx={styles.userName}>
                                      {userFirstName} - {roleName}
                                    </Typography>
                                    <Typography fontSize="12px">{schoolName}</Typography>
                                  </Box>
                                </Box>
                              </MenuItem>
                            </Tooltip>
                          );
                        })}
                    </Collapse>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        {/* Temporary hide profile settings this time */}
        {/* {activeRoleId && activeRoleId !== Number(UserRoleEnum.ORG_ADMIN) && (
          <Box>
            <TitleSection title="menu.dropdown.profile.settings" />
            <UserMenuItem
              img={echAvaImg}
              messageId={
                activeRoleId === Number(UserRoleEnum.TEACHER)
                  ? 'menu.dropdown.profile.go.to.personal.account'
                  : 'menu.dropdown.edit.profile'
              }
              onClick={handleMoveToOrganization}
            />
          </Box>
        )} */}

        <TitleSection title="menu.dropdown.other.settings" />

        {renderSwitchLanguage(locale)}

        <UserMenuItem icon={logoutIcon} messageId="menu.dropdown.signOut" onClick={handleLogout} marginLeft={true} />
      </Menu>
    </Box>
  );
});

import { UserRoleEnum } from '@/types/Dashboard';
import { ELanguageShortName, ELanguageName, ELocaleCode, OTP_STATUS, OTP_STEP } from './enums';

import flagUK from '@/assets/images/flag-uk.svg';
import flagVN from '@/assets/images/flag-vn.svg';

export const API_PREFIX = 'api';
export const API_VERSION = 'v1';
export const API_VERSION_V2 = 'v2';

export const ZERO = 0;
export const NUMBER_ONE = 1;
export const NUMBER_SECOND = 2;
export const NUMBER_THREE = 3;
export const NUMBER_FOUR = 4;
export const NUMBER_FIVE = 5;
export const NUMBER_EIGHT = 8;
export const NUMBER_TWELVE = 12;
export const NOT_EXIST_INDEX = -1;
export const FIRST_ITEM_INDEX = 0;
export const SECOND_ITEM_INDEX = 1;
export const THIRD_ITEM_INDEX = 2;
export const FOURTH_ITEM_INDEX = 3;
export const FIRST_ITEM_NUMBER = 1;
export const SECOND_ITEM_NUMBER = 2;
export const HUNDRED = 100;
export const ZERO_POINT_TWO = 0.2;
export const ZERO_POINT_FOUR = 0.4;
export const ZERO_POINT_FIVE = 0.5;
export const ZERO_POINT_SIX = 0.6;
export const ZERO_POINT_EIGHT = 0.8;
export const LIMIT_CHARACTER = 10;
export const UINT32_NUMBER = 0xffffffff;

export const PAGINATION = {
  DEFAULT_TOTAL_PAGES: '1',
  FIRST_PAGE_NUMBER: '1',
  DEFAULT_PAGE_SIZE: '10',
  DEFAULT_CURRENT_PAGE: '1',
  STEP: 1,
  MIN_LOAD_MORE: 5,
  BOUNDARY_LOAD_MORE: 2,
  PAGE_SIZE_TWENTY: '20',
  PAGE_SIZE_THIRTY: '30',
};

export const API_ENTITY = {
  USERS: 'users',
  SCHOOLS: 'schools',
  PROGRAMS: 'programs',
  DISTRICTS: 'cities',
  CITIES: 'districts',
  SCHOOL: 'school',
  CLASSES: 'classes',
  SCHEDULES: 'schedules',
  HOMEWORK: 'homework',
  LESSONS: 'lessons',
  LESSON_CONTENTS: 'lesson-contents',
  ATTENDEES: 'attendees',
  LESSON: 'lesson',
  DASHBOARDS: 'dashboards',
  REPORTS: 'reports',
  REPORT: 'report',
  ATTEMPTED: 'attempted',
  MATERIALS: 'materials',
  SKILLS: 'skills',
  PROFILES: 'profiles',
  TRANSFER: 'transfer',
  AUTH: 'auth',
  ROLES: 'roles',
  LESSON_MODULES: 'lesson-modules',
  PAYMENT: 'payment',
  UNITS: 'units',
  OTP: 'otp',
  ADS: 'ads',
  EMAIL_VERIFICATION: 'email-verification',
};

export const API_ENTITY_DATA_KEY = {
  USERS: 'users',
  SCHOOLS: 'schools',
  PROGRAMS: 'programs',
  DISTRICTS: 'cities',
  CITIES: 'districts',
  SCHOOL: 'school',
  CLASS: 'class',
  CLASSES: 'classes',
  SCHEDULES: 'schedules',
  HOMEWORK: 'homework',
  LESSONS: 'lessons',
  LESSON: 'lesson',
  LESSON_CONTENTS: 'lessonContents',
  ATTEMPTED_HOMEWORK: 'attemptedHomework',
  STUDENTS: 'students',
};

export const KEY_REACT_QUERY = {
  SCHOOLS_INFINITE: 'schools-infinite',
};

export const KEY_PROPERTY_PARAMS = {
  SCHOOLIDS: 'schoolIds',
  LESSON_CONTENT: 'lessonContent',
  NAME: 'name',
  PROGRAM_NAME: 'program_name',
  SECONDS_PARAMS: 'secondsParams',
};

export const DEFAULT_COMMON_LISTING_STORE_NAME = 'commonListingPage';
export const DEFAULT_COMMON_LISTING_STORE_PARAMS = {
  page: PAGINATION.DEFAULT_CURRENT_PAGE,
  pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
  totalPages: PAGINATION.DEFAULT_TOTAL_PAGES,
};

export const PAGE_ACTIONS = {
  CREATE_CLASS_ROSTER: 'CREATE_CLASS_ROSTER',
  EDIT_CLASS_ROSTER: 'EDIT_CLASS_ROSTER',
  DELETE_CLASS_ROSTER: 'DELETE_CLASS_ROSTER',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  DELETE_SCHEDULE_HOMEWORK: 'DELETE_SCHEDULE_HOMEWORK',
  DELETE_SCHEDULE_LESSON: 'DELETE_SCHEDULE_LESSON',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  EDIT_SCHEDULE_HOMEWORK: 'EDIT_SCHEDULE_HOMEWORK',
  EDIT_PASSED_SCHEDULE_HOMEWORK: 'EDIT_PASSED_SCHEDULE_HOMEWORK',
  EDIT_SCHEDULE_LESSON: 'EDIT_SCHEDULE_LESSON',
  CREATE_SCHEDULE: 'CREATE_SCHEDULE',
  CREATE_SCHEDULE_HOMEWORK: 'CREATE_SCHEDULE_HOMEWORK',
  CREATE_SCHEDULE_LESSON: 'CREATE_SCHEDULE_LESSON',
  ADD_SCHOOL: 'ADD_SCHOOL',
  EDIT_SCHOOL: 'EDIT_SCHOOL',
  VIEW_SCHOOL: 'VIEW_SCHOOL',
};

export const SYMBOLS = {
  HYPHEN: '-',
  COMMA: ',',
  SLASH: '/',
  PERCENT: '%',
  COLON: ':',
  DOT: '.',
  OPEN_PARENTHESES: '(',
  CLOSE_PARENTHESES: ')',
  ETC: '...',
  ASTERISK: '*',
  PLUS: '+',
  BULLET: '•',
};
export const TYPE_SEARCH_FULL_SCHOOL_LIST = 'full';

export const TAB_NAME = {
  DETAILS: 'DETAILS',
  ROSTER: 'ROSTER',
  HOMEWORK: 'HOMEWORK',
  LESSONS: 'LESSONS',
  LESSON_REVIEW: 'LESSON_REVIEW',
  SCHEDULE_LESSON: 'SCHEDULE_LESSON',
  UPCOMING_SCHEDULES: 'UPCOMING_SCHEDULES',
  LESSON_DETAILS: 'LESSON_DETAILS',
  LESSON_GUIDES: 'LESSON_GUIDES',
  PARENTS_GUIDES: 'PARENTS_GUIDES',
  CONTENT_GUIDES: 'CONTENT_GUIDES',
  ACTIVITY_GUIDES: 'ACTIVITY_GUIDES',
  ACTIVITY_REPORT: 'ACTIVITY_REPORT',
  SKILL_REPORT: 'SKILL_REPORT',
  PROGRAMS: 'PROGRAMS',
};

export const PAGES_TITLE = {
  HOME: 'common.title.home',
  LOGIN: 'common.title.login',
  REGISTER: 'common.title.register',
  FORGOT_PASSWORD: 'common.title.forgotPassword',
  PAYMENT: 'common.title.payment',
  DASHBOARD: 'common.title.dashboard',
  PROFILE: 'common.title.profile',
  USER: 'common.title.user',
  SCHOOL: 'common.title.school',
  LIBRARY: 'common.title.library',
  PROGRAM: 'common.title.program',
  CLASS_ROSTER: 'common.title.classRoster',
  PERMISSION_DENIED: 'common.title.permissionDenied',
  SCHEDULE: 'common.title.schedule',
  PROGRAM_DETAIL: 'common.title.programDetail',
  LEARNING: 'common.title.learning',
  CHECK_PAYMENT: 'common.title.checkPayment',
  BUSINESS_SELECTION: 'common.title.businessSelection',
  SUPPORT: 'common.title.support',
  VERIFY_EMAIL: 'common.title.verify.email',
};

export const PAGES_NAME = {
  DASHBOARD: 'dashboard',
  PROFILE: 'profile',
  USER: 'user',
  SCHOOL: 'school',
  LIBRARY: 'library',
  PROGRAM: 'program',
  CLASS_ROSTER: 'class-roster',
  PERMISSION_DENIED: 'permission-denied',
  SCHEDULE: 'schedule',
  PROGRAM_DETAIL: 'programDetail',
  LEARNING: 'learning',
  CHECK_PAYMENT: 'check-payment',
  BUSINESS_SELECTION: 'business-selection',
  SUPPORT: 'support',
};

export const ENTITY_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  UPCOMING: 'upcoming',
  PASSED: 'passed',
};

export const clientRoleArr = [`${UserRoleEnum.PARENTS}`, `${UserRoleEnum.STUDENT}`, `${UserRoleEnum.TEACHER}`];
export const dashboardRoleArr = [`${UserRoleEnum.ORG_ADMIN}`, `${UserRoleEnum.SCHOOL_ADMIN}`];

export const PAGES_TITLE_HEADERS = {
  CLIENT_HOME_PAGE: 'client.titleHeader.homepage',
  CLASS_ROSTER_PAGE: 'client.titleHeader.classRoster',
  CLIENT_PROGRAMS_PAGE: 'client.titleHeader.programs',
};

export const ROUTE_ENTITY = {
  ADMIN: 'dashboard',
  CLIENT: 'client',
};

export const APP_OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
};

export const TERMS_OF_SERVICE_URL = 'https://chichchoe.vn/policy/dieu-khoan-su-dung.html';

export const PRIVACY_POLICY = 'https://chichchoe.vn/policy/chinh-sach-bao-mat.html';

export const SUPPORT_PAGE_URL = 'https://chichchoe.freshdesk.com/support/solutions';

export const SENTRY_TRACKING_URLS = [
  'https://app.staging.chichchoe.vn/',
  'https://app.chichchoe.vn/',
  'https://app.dev.chichchoe.vn/',
];

export const APP_STORE_URL = {
  APPLE: 'https://apps.apple.com/vn/app/ch%C3%ADch-ch%C3%B2e/id6449670343',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=vn.chichchoe.app',
};

export const mappingLanguageOptionsFromLanguageCode = {
  [ELocaleCode.EN]: { name: ELanguageName.EN, shortName: ELanguageShortName.EN, flag: flagUK },
  [ELocaleCode.VI]: { name: ELanguageName.VI, shortName: ELanguageShortName.VI, flag: flagVN },
};
export const OTP_VERIFY_IDLE = { step: OTP_STEP.VERIFY, status: OTP_STATUS.IDLE };
export const OTP_REQUEST_INITIALIZING = {
  step: OTP_STEP.REQUEST,
  status: OTP_STATUS.INITIALIZING,
};
export const OTP_FINISH_IDLE = { step: OTP_STEP.FINISH, status: OTP_STATUS.IDLE };
export const OTP_REQUEST_IDLE = { step: OTP_STEP.REQUEST, status: OTP_STATUS.IDLE };

export const API_ACTION = {
  BULK_UPLOADS: 'bulk-uploads',
  CHECK_PHONE: 'check-phone',
  IMPORT: 'import',
  BULK_SCHEDULE: 'bulk-schedules',
  GENERATE: 'generate',
  OTP_PROVIDER: 'provider',
  VERIFY_OTP: 'verify',
  GENERATE_OTP: 'generate',
  REGISTER: 'register-with-phone',
  RESET_PASSWORD: 'reset-password-with-phone',
};

export const API_FEATURE = {
  AI_REPORTS: 'ai-reports',
  B2C: 'b2c',
};

export const EMPTY_H5P_PATH = 'rxempty';
